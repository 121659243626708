import React from 'react'
import {useStaticQuery, graphql} from "gatsby"
import BackgroundImage from 'gatsby-background-image'

const Banner = () => {
    const data = useStaticQuery(graphql`
        query {
            background: file(relativePath: { eq: "Banner/Banner/background-faded-cropped-en.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <div id="banner" className="banner">
            <BackgroundImage
                Tag="div"
                id="backgroundImage"
                fluid={data.background.childImageSharp.fluid}
                backgroundColor={`#333333`}
            >
                <div className="main-text">
                    <p>New Avenues for Information Security</p>
                </div>
            </BackgroundImage>
        </div>
    )
}

export default Banner