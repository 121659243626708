import React from 'react'
//import Logo from "../../images/svgs/alarm-logo-eng.inline.svg";
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"

const ProjectBlurb = () => {
    const data = useStaticQuery(graphql`
        query {
            thWildauLogo: file(relativePath: { eq: "Footer/th-wildau.png" }) {
                childImageSharp {
                    fluid(maxWidth: 200, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <div className="project-blurb">
            <span className="anchor" id="project">anchor</span>
            <div className="container">
                <div className="inner-blurb">
                    <div className="content">
                        <h3>ALARM</h3>
                        <h4>Awareness Lab SME Information Security</h4>
                        <p>
                            Small and medium-sized enterprises (SMEs) collect, process, and use large amounts of sensitive data with the help of digital IT solutions. 
                            The problem is that these companies tend to underestimate the risks and dangers posed by ever more ingenious attackers. 
                            A lack of concern for or ignorance of information security, the violation of company guidelines on information security, or the absence of any such guidelines are all risks confronting businesses of all shapes and sizes. 
                            The numerous attack points in an enterprise represent defects in the security system. These can have delayed consequences for SMEs. 
                            This is where the multidisciplinary research project Awareness Lab SME (ALARM) Information Security comes into play.
                        </p>
                        
                        <h4>Technical University of Applied Sciences Wildau (TH Wildau)</h4>
                        <p>
                            TH Wildau, which was founded in 1991 in the state of Brandenburg, is a very active university of applied sciences with a strong focus on research: this has a positive influence on the quality of teaching it offers. 
                            It has proven itself to be a competent and reliable partner in a large number of externally funded projects nationwide. 
                            The university’s main focus is on practical applications, along with research and development (R & D) and knowledge and technology transfer. 
                        </p>
                        <div className="th-logo">
                            <a href="https://www.th-wildau.de/" target="_blank" rel="noreferrer" aria-label="Link zur Website Fokus Frauenhofer">
                                <Img className="image" fluid={data.thWildauLogo.childImageSharp.fluid}/>
                            </a>
                        </div>
                    </div>
                    <div className="content">
                     <h3>Project</h3>
                        <p>
                            Awareness Lab SME (ALARM) Information Security aims, over the space of three years, to create a complete scenario that ranges from raising awareness in SMEs 
                            and supporting them in the area of information security to creating tools to enable them to support themselves. Each project year is split into three consecutive phases, each manifesting as an agile and participative process. 
                            The phases are geared to the development of innovative analogue and digital learning scenarios, “on-site attacks”, and scientific testing, including awareness measurements, quizzes, and tests. 
                            This whole scenario is intended to perform the vital task of raising awareness among executives and employees and thus lead to targeted staff development in SMEs and VSBs, which at present is not standard in these companies. 
                            The idea is to make IT security tangible and comprehensible by connecting it with digital processes, which are increasingly prevalent in the workplace. 
                            Participants will also be emotionally engaged and actively involved in the development of measures. This should create a durable culture of information security across the organization.   
                        </p>
                     </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectBlurb