import React from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"

const ContactBlurb = () => {
    const data = useStaticQuery(graphql`
        query {
            contactImage: file(relativePath: { eq: "Contact.png" }) {
                childImageSharp {
                    fluid(maxWidth: 656, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <div className="contact-blurb">
            <span className="anchor" id="contact">anchor</span>
            <div className="container">
                <div className="inner-blurb">
                    <div className="content left">
                        <h3>Contact</h3>
                        <p>
                            Technical University of Applied Sciences Wildau <br />
                            Hochschulring 1 <br />
                            15745 Wildau
                        </p>
                        <p>
                            Prof. Margit Scholl <br />
                            Project management  <br />
                            Faculty: Business, Computing and Law
                        </p>
                        <p>
                            +49 3375 508 917 <br />
                            <a href="mailto:alarm@th-wildau.de">alarm@th-wildau.de</a> <br />
                            <a href="http://www.th-wildau.de/scholl" rel="noreferrer" target="_blank">http://www.th-wildau.de/scholl</a> <br />
                            Haus 100, Raum 106 
                        </p>
                    </div>
                    <div className="content right">
                        <p className="break">
                            <br />
                            <br />
                            <br />
                            <span className="english-title-extra-break"><br /></span>
                        </p>
                        <p>
                            Regina Schuktomow <br />
                            Operational project management  <br />
                            <br className="no-fachbereich-break" />
                            <span><br /></span>
                        </p>
                        <p>
                            +49 3375 508 239 <br />
                            Haus 100, Raum 304 
                            <br />
                            <br />
                        </p>
                    </div>
                    <div className="images">
                        <Img fluid={data.contactImage.childImageSharp.fluid}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactBlurb