import React from 'react'
import {StaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"

class PartnerBlurb extends React.Component {
    state = { 
        adress: "#partner"
    } 

    sendMail(mailName, domain, tld){
        this.setState({
            adress: "mailto:" + mailName + "@" + domain + "." + tld
        })
    }

    /*
        <a href="#" className="cryptedmail"
            data-name="info"
            data-domain="example"
            data-tld="org"
            onClick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;">
        </a>
    */

    render(){
        const mailAdress = this.state.adress;

        return (
            <StaticQuery
                query = {graphql`
                    query {
                        gamebook: file(relativePath: { eq: "Partner/gamebook-logo-red.png" }) {
                            childImageSharp {
                                fluid(maxWidth: 2439, quality: 90) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        knownSense: file(relativePath: { eq: "Partner/known-sense.png" }) {
                            childImageSharp {
                                fluid(maxWidth: 2133, quality: 90) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        sudile: file(relativePath: { eq: "Partner/sudile.jpg" }) {
                            childImageSharp {
                                fluid(maxWidth: 727, quality: 90) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        thinkingObjects: file(relativePath: { eq: "Partner/thinking-objects.png" }) {
                            childImageSharp {
                                fluid(maxWidth: 586, quality: 90) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                `}
                render = {data => ( 
                        <div className="partner-blurb">
                            <span className="anchor" id="partner">anchor</span>
                            <div className="container">
                                <h3>Partners</h3>
                            </div>
                            <div className="experimental-game">
                                <div className="container">
                                    <div className="inner-blurb">
                                        <div className="content info">
                                            <h4>Gamebook Studio</h4>
                                            <div className="company">
                                                <div className="logo">
                                                    <Img fluid={data.gamebook.childImageSharp.fluid}/>
                                                </div>
                                                <div className="contact-person">
                                                    <p><br />
                                                    <br />
                                                        Ulrike Küchler <br />
                                                        +49 345 213 890 03 <br />
                                                        <a href={mailAdress} className="cryptedmail"
                                                            data-name="ulrike.kuechler"
                                                            data-domain="gamebook"
                                                            data-tld="io"
                                                            onClick={() => this.sendMail("ulrike.kuechler", "gamebook", "io")}>
                                                        </a> <br/>
                                                        <a href="https://gamebook.io/" rel="noreferrer" target="_blank">www.gamebook.io</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="content description">
                                            <p>
                                                At Gamebook storytelling is our business-and our passion. Our vision is to create and deliver the best storytelling games available. 
                                                Our mission is to provide the necessary knowledge and the network along with a tool set that is easy to use and comprehensive at the same time.
                                                <br/><br/>
                                                We achieve these goals by thinking out of the box and combining our web-based software with agile networks where we bring IT developers, 
                                                producers, game designers, artists, and authors together in a way nobody ever imagined-all in one place. Since our foundation in 2010, 
                                                this idea has driven us and our team has been continuously growing ever since. 
                                                <br/><br/>
                                                Today, we offer Games and Training-as-a-Service solutions for all kinds of projects: 
                                                from IP based interactive Visual Novels to custom learning experiences that facilitate the power of good stories.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="known-sense">
                                <div className="container">
                                    <div className="inner-blurb">
                                        <div className="content description">
                                            <p> 
                                                Known_sense is committed to providing a look behind the scenes using the requisite knowledge of people as “black boxes”: the result is lively communication on the subject of security and effective awareness-raising. 
                                                The company has been working in this way since 2002. Globally, we were the very first agency to be completely focused on awareness-raising, 
                                                and we are still the only firm that serves the entire value chain from impact studies on security to conceptual design, consulting, product development, design, media production, training, gamification, 
                                                evaluation, and much more. In the two decades since the company was founded, our consultants, creatives, psychologists, 
                                                and trainers have implemented measures for more than a hundred small and large companies in almost fifty countries and in twenty-five different languages. 
                                                These measures are derived from a knowledge of the paradoxical urges that drive people in the context of security and—to quote numerous clients and customers—get them to do “peculiar” things: 
                                                things that need to be examined carefully and require special responses.
                                                <br/> <br/>
                                                Our task within the project ALARM is to conduct qualitative impact studies based on depth psychology—we are responsible, in other words, 
                                                for a baseline study of information security and awareness within SMEs, for testing the concepts and products, and for developing, visualizing, and manufacturing seven game-based, analogue learning scenarios.
                                            </p>
                                        </div>
                                        <div className="content info">
                                            <h4>known_Sense</h4>
                                            <div className="company">
                                                <div className="contact-person">
                                                    <p>
                                                        Dietmar Pokoyski <br />
                                                        02203 1831618 <br />
                                                        <a href={mailAdress} className="cryptedmail"
                                                            data-name="pokoyski"
                                                            data-domain="known-sense"
                                                            data-tld="de"
                                                            onClick={() => this.sendMail("pokoyski", "known-sense", "de")}>
                                                        </a> <br/>
                                                        <a href="http://www.known-sense.de/" rel="noreferrer" target="_blank">www.known-sense.de</a>
                                                    </p>
                                                </div>
                                                <div className="logo">
                                                    <Img fluid={data.knownSense.childImageSharp.fluid}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="thinking-objects">
                                <div className="container">
                                    <div className="inner-blurb">
                                        <div className="content info">
                                            <h4>Thinking Objects</h4>
                                            <div className="company">
                                                <div className="logo">
                                                    <Img fluid={data.thinkingObjects.childImageSharp.fluid}/>
                                                </div>
                                                <div className="contact-person">
                                                    <p><br />
                                                        Martina Vogt <br />
                                                        +49 152- 05706824 <br />
                                                        <a href={mailAdress} className="cryptedmail"
                                                            data-name="martina.vogt"
                                                            data-domain="to"
                                                            data-tld="com"
                                                            onClick={() => this.sendMail("martina.vogt", "to", "com")}>
                                                        </a> <br/>
                                                        <a href="https://to.com/" rel="noreferrer" target="_blank">www.to.com</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="content description">
                                            <p>
                                                IT. High-performance. Safe. Customer-oriented. The owner-operated company Thinking Objects—TO, for short—is a competent IT service provider with a focus on IT security. 
                                                For more than twenty-five years TO has been delivering solutions tailored to the market that offer support, relieve stress, promote optimization, and provide security for the IT departments in SMEs and corporations.
                                                <br/> <br/>
                                                TO brings to the ALARM project its expertise in conceptualizing and executing “on-site attacks”. 
                                                The assessments of these attacks lead to recommendations for action for low-threshold safety concepts, on the basis of which each company can enhance its ability to make independent decisions with a bearing on IT security.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sudile">
                                <div className="container">
                                    <div className="inner-blurb">
                                        <div className="content description">
                                            <p>
                                                Located in Potsdam since 2004, Sudile is a company that focuses on developing and adjusting learning platforms, providing consultations on the implementation of e-Learning and Blended Learning, 
                                                trainings on topics related to e-Learning, and the didactic conceptualization of learning services.
                                                <br/><br/>
                                                In the ALARM project, mathematical chemist Dr Rainer Brüggemann uncovers the connections (“matches”) between and within two groups: a) anonymous subjects and b) cyberattacks. 
                                                The “matching” research approach contributes to awareness measurements, promotes a better understanding of the relationship between cyberattacks and the companies affected by them, and helps define learning success.
                                            </p>
                                        </div>
                                        <div className="content info">
                                            <h4>Sudile</h4>
                                            <div className="company">
                                                <div className="contact-person">
                                                    <p>
                                                        Dr. Rainer Brüggemann <br />
                                                        <a href={mailAdress} className="cryptedmail"
                                                            data-name="brg_home"
                                                            data-domain="web"
                                                            data-tld="de"
                                                            onClick={() => this.sendMail("brg_home", "web", "de")}>
                                                        </a> <br/>
                                                        <a href="https://www.sudile.com/" rel="noreferrer" target="_blank">www.sudile.com</a>
                                                    </p>
                                                </div>
                                                <div className="logo">
                                                    <Img fluid={data.sudile.childImageSharp.fluid}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                )}
            />
        )
    }
}

export default PartnerBlurb