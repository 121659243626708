import React from "react"
import "../styles/styles.scss"
//Import Components
import Header from "../components/english/header"
import Banner from "../components/english/banner"
import ProjectBlurb from "../components/english/projectBlurb"
import GoalsAndMethodsBlurbBlurb from "../components/english/goalsAndMethodsBlurb"
import PublicationsBlurb from "../components/english/publicationsBlurb"
import TeamBlurb from "../components/english/teamBlurb"
import PartnerBlurb from "../components/english/partnerBlurb"
import ContactBlurb from "../components/english/contactBlurb"
import Footer from "../components/english/footer"
import DigitalLearningScenarios from "../components/english/dLS"

const IndexPage = () => (
  <div>
    <Header />
    <Banner />
    <ProjectBlurb />
    <GoalsAndMethodsBlurbBlurb />
    <DigitalLearningScenarios />
    <PublicationsBlurb />
    <PartnerBlurb />
    <TeamBlurb />
    <ContactBlurb />
    <Footer />
  </div>
)

export default IndexPage
