import React, {useContext} from "react"
import { useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
//import 'reactjs-popup/dist/index.css';
import {GlobalDispatchContext,  GlobalStateContext} from "../../context/GlobalContextProvider"

const DigitalLearningScenarios = () => {
    const dispatch = useContext(GlobalDispatchContext);
    const state = useContext(GlobalStateContext);
    
    //const dispatch = useContext(GlobalDispatchContext);
    //state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            arrow: file(relativePath: { eq: "ui-arrow.png" }) {
                childImageSharp {
                    fluid(maxWidth: 74, quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            digitalLS1: file(relativePath: { eq: "dLS/ls01.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            digitalLS2: file(relativePath: { eq: "dLS/ls02.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            digitalLS3: file(relativePath: { eq: "dLS/ls03.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            digitalLS4: file(relativePath: { eq: "dLS/ls04.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            digitalLSX: file(relativePath: { eq: "dLS/Cover-DigitalLS3.webp" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            digitalLS5: file(relativePath: { eq: "dLS/ls05.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            digitalLS6: file(relativePath: { eq: "dLS/ls06.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            digitalLS7: file(relativePath: { eq: "dLS/ls07.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            konzeptImage: file(relativePath: { eq: "dLS/konzept-digitale-serious-game-ls.png" }) {
                childImageSharp {
                    fluid(maxHeight: 500, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            konzeptAnalogImage: file(relativePath: { eq: "dLS/konzept-analog-serious-game-ls.png" }) {
                childImageSharp {
                    fluid(maxHeight: 500, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            analogLS01: file(relativePath: { eq: "dLS/als01.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            analogLS02: file(relativePath: { eq: "dLS/als02.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            analogLS03: file(relativePath: { eq: "dLS/als03.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            analogLS04: file(relativePath: { eq: "dLS/als04.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            analogLS05: file(relativePath: { eq: "dLS/als05.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            analogLS06: file(relativePath: { eq: "dLS/als06.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            analogLS07: file(relativePath: { eq: "dLS/als07.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            analogLS07Roulette: file(relativePath: { eq: "aLS/infoklassen-roulette.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            digitalSeSeC: file(relativePath: { eq: "dLS/sesec.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            digitalSeSeC: file(relativePath: { eq: "dLS/sesec.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            konzeptDigiSeriousGamesPDF: file(relativePath: { eq: "dLS/handout-dLS-A4-final.pdf"}) {
                name
                publicURL
            }
            konzeptAnalogGamesPDF: file(relativePath: { eq: "dLS/handout-aLS-A4-final.pdf"}) {
                name
                publicURL
            }
        }
    `)

    return (
        <div className="learningScenarios-blurb">
            <span className="anchor" id="learningScenarios">anchor</span>
            <div className="container">
                <div class="content info">
                <p>
                    Es werden insgesamt sieben digitale und analoge Serious Games
                    zur Verfügung gestellt. Die digitalen Versionen können direkt 
                    genutzt werden. Für die Durchführung ananloger Awareness- und 
                    Schulungsmaßnahmen in Ihren Unternehmen, stehen Anleitungen für 
                    die Moderation und alle benötigten Materialien zur Verfügung.
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                </p>
                </div>
                <div className="rows">
                    <div className={state.digitalId}>
                        <div className="andibox">
                            <p>Seven digital<br /> serious games </p>
                            <p>Only in German and limited for internal usage</p>
                            <div class="andibutton">
                                <button onClick={() => dispatch({type: "CHANGE_LS_ANALOG"})}>
                                    Analog Serious Games
                                </button>
                                <button onClick={() => dispatch({type: "CHANGE_LS_DIGITAL"})}>
                                    Digital Serious Games
                                </button>
 
                                {/* <button onClick={() => dispatch({type: "CHANGE_LS_ONSITE"})}>
                                    Security concepts
                                </button> */}
                                {/* <button onClick="">
                                    Security Self Check
                                </button> */}
                            </div>
                        </div>
                    </div>
                    <div className={state.analogId}>
                        <div className="andibox">
                            <p>Seven analog<br /> serious games </p>
                            <p>Only in German and limited for internal usage</p>
                            <div class="andibutton">
                                <button onClick={() => dispatch({type: "CHANGE_LS_ANALOG"})}>
                                    Analog Serious Games
                                </button>
                                <button onClick={() => dispatch({type: "CHANGE_LS_DIGITAL"})}>
                                    Digital Serious Games
                                </button>
                            </div>
                        </div>
                    </div>
                   {/* < div className={state.onSiteId}>
                        <div className="andibox">
                            <p>Angriffsarten &<br />Sicherheitskonzepte </p>
                            <div class="andibutton">
                                <button onClick={() => dispatch({type: "CHANGE_LS_DIGITAL"})}>
                                    Digitale Serious Games
                                </button>
                                <button onClick={() => dispatch({type: "CHANGE_LS_ANALOG"})}>
                                    Analoge Serious Games
                                </button>
                                <button onClick={() => dispatch({type: "CHANGE_LS_ONSITE"})}>
                                    Sicherheitskonzepte
                                </button>
                                <button onClick="">
                                    Security Self Check
                                </button>
                            </div>
                        </div> 
                    </div>*/}
{/* xxxxxxxxxxxxxxxx Sicherheitekonzeptexxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx*/}
                <div className={state.onSiteId}>
                        <div className="box">
                            <div className="image-container">
                                <a className="subtitle" 
                                    href="/als1"  
                                    target="_blank" rel="noreferrer" 
                                    aria-label="lernszenario 1">
                                <Img fluid={data.analogLS01.childImageSharp.fluid} className="image"/>
                                </a>
                            </div>
                            <p className="digitalSmall">VOA</p>
                            <p className="subtitle">Anleitung 1</p>
                        </div>
                    </div>
{/* xxxxxxxxxxxxxxxx analoge Spiele xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx*/}
                    <div className={state.analogId}>
                        <div className="box">
                            <div className="paper-container">
                                <div className="image-container">
                                <a href={data.konzeptAnalogGamesPDF.publicURL} download>
                                    <Img fluid={data.konzeptAnalogImage.childImageSharp.fluid} className="image"/>
                                </a>
                                </div>
                                <p>
                                    <a href={data.konzeptAnalogGamesPDF.publicURL} target="_blank" rel="noreferrer">
                                    Storykonzept analoger Serious Games</a>
                                </p>
                                <p className="subtitle">Download PDF</p>
                            </div> 
                        </div>
                    </div>
                    <div className={state.analogId}>
                        <div className="box">
                            <div className="image-container">
                                <a className="subtitle" 
                                    href="/als1"  
                                    target="_blank" rel="noreferrer" 
                                    aria-label="lernszenario 1">
                                <Img fluid={data.analogLS01.childImageSharp.fluid} className="image"/>
                                </a>
                            </div>
                            <p className="digitalSmall">Homeoffice</p>
                            <p className="subtitle">Sicher zuhause <br/>wohnen & arbeiten</p>
                        </div>
                    </div> 
                    <div className={state.analogId}>
                        <div className="box">
                            <div className="image-container">
                                <a className="subtitle" 
                                    href="/als2"  
                                    target="_blank" rel="noreferrer" 
                                    aria-label="lernszenario 1">
                                <Img fluid={data.analogLS02.childImageSharp.fluid} className="image"/>
                                </a>
                            </div>
                            {/* <p className="digitalSmall">Analoges Serious Game</p> */}
                            <p className="digitalSmall">Passwortschutz & Multi-<br />Faktor-Authentifizierung</p>
                        </div>
                    </div> 
                    <div className={state.analogId}>
                        <div className="box">
                            <div className="image-container">
                                <a className="subtitle" 
                                    href="/als3"  
                                    target="_blank" rel="noreferrer" 
                                    aria-label="lernszenario 1">
                                <Img fluid={data.analogLS03.childImageSharp.fluid} className="image"/>
                                </a>
                            </div>
                            <p className="digitalSmall">CEO Fraud</p>
                            <p className="subtitle">Die 5 Phasen des CEO-Frauds</p>
                        </div>
                    </div> 
                    <div className={state.analogId}>
                        <div className="box">
                            <div className="image-container">
                                <a className="subtitle" 
                                    href="/als4"  
                                    target="_blank" rel="noreferrer" 
                                    aria-label="lernszenario 1">
                                <Img fluid={data.analogLS04.childImageSharp.fluid} className="image"/>
                                </a>
                            </div>
                            <p className="digitalSmall">Mobile Apps</p>
                            <p className="subtitle">Mobile Kommunikation,<br /> Apps & Co.</p>
                        </div>
                    </div>

                    <div className={state.analogId}>
                        <div className="box">
                            <div className="image-container">
                                <a className="subtitle" 
                                    href="/als5"  
                                    target="_blank" rel="noreferrer" 
                                    aria-label="lernszenario 1">
                                <Img fluid={data.analogLS05.childImageSharp.fluid} className="image"/>
                                </a>
                            </div>
                            <p className="digitalSmall">Social Engineering</p>
                            <p className="subtitle">Cyber Pairs</p>
                        </div>
                    </div>
                    <div className={state.analogId}>
                        <div className="box">
                            <div className="image-container">
                                <a className="subtitle" 
                                    href="/als6"  
                                    target="_blank" rel="noreferrer" 
                                    aria-label="lernszenario 1">
                                <Img fluid={data.analogLS06.childImageSharp.fluid} className="image"/>
                                </a>
                            </div>
                            <p className="digitalSmall">Daten- & Informationsschutz</p>
                            <p className="subtitle">Die wichtigsten Schutzstrategien</p>
                        </div>
                    </div> 
                    <div className={state.analogId}>
                        <div className="box">
                            <div className="image-container">
                                <a className="subtitle" 
                                    href="/als7"  
                                    target="_blank" rel="noreferrer" 
                                    aria-label="lernszenario 1">
                                <Img fluid={data.analogLS07.childImageSharp.fluid} className="image"/>
                                </a>
                            </div>
                            <p className="digitalSmall">Infoklassen-Roulette</p>
                            <p className="subtitle">Informationen sicher klassifizieren</p>
                        </div>
                    </div> 
                    <div className={state.analogId}>
                        <div className="box">
                            <div className="image-container">
                                <a className="subtitle" 
                                    href="https://diz.wildau.biz/roulette/index.html"  
                                    target="_blank" rel="noreferrer" 
                                    aria-label="lernszenario 1">
                                <Img fluid={data.analogLS07Roulette.childImageSharp.fluid} className="image"/>
                                </a>
                            </div>
                            <p className="digitalSmall">Infoklassen-Roulette</p>
                            <p className="subtitle">App (online)</p>
                        </div>
                    </div>
                    <div className={state.analogId}>     
                        <div className="whitebox">
                            <div>
                                <div>
                                </div>
                                <p style={{width: "180px"}}></p>
                                <p className="subtitle"></p> 
                            </div>
                        </div>
                    </div>
{/* xxxxxxxxxxxxxxxx digitale Spiele xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx*/}

                    <div className={state.digitalId}>
                        <div className="box">
                            <div className="paper-container">
                                <div className="image-container">
                                <a href={data.konzeptDigiSeriousGamesPDF.publicURL} download>
                                    <Img fluid={data.konzeptImage.childImageSharp.fluid} className="image"/>
                                </a>
                                </div>
                                <p>
                                    <a href={data.konzeptDigiSeriousGamesPDF.publicURL} target="_blank" rel="noreferrer">
                                    Storykonzept digitale Serious Games</a>
                                </p>
                                <p className="subtitle">Download PDF</p>
                            </div> 
                        </div>
                    </div>
                    
                    
                    <div className={state.digitalId}>
                        <div className="box">
                            <div className="image-container">
                                <a className="subtitle" 
                                    href="/ls1"  
                                    target="_blank" rel="noreferrer" 
                                    aria-label="lernszenario 1">
                                <Img fluid={data.digitalLS1.childImageSharp.fluid} className="image"/>
                                </a>
                            </div>
                            <p className="digitalSmall">Der erste Tag</p>
                            <p className="subtitle">Social Engineering <br/> & Passwortschutz</p>
                        </div>
                    </div> 
                    <div className={state.digitalId}>
                        <div className="box">
                            <div className="image-container">
                            <a className="subtitle" 
                                    href="/ls2"  
                                    target="_blank" rel="noreferrer" 
                                    aria-label="lernszenario 2">
                                <Img fluid={data.digitalLS2.childImageSharp.fluid} className="image"/>
                                </a>
                            </div>
                            <p className="digitalSmall">Der Hackerangriff</p>
                            <p className="subtitle">
                            Social-Engineering-Methoden<br/> & -Werkzeuge
                            </p> 
                        </div>
                    </div>
                    <div className={state.digitalId}>
                        <div className="box">
                            <div className="image-container">
                            <a className="subtitle" 
                                    href="/ls3"  
                                    target="_blank" rel="noreferrer" 
                                    aria-label="lernszenario 3">
                                <Img fluid={data.digitalLS3.childImageSharp.fluid} className="image"/>
                                </a>
                            </div>
                            <p className="digitalSmall">Die Spurensuche</p>
                            <p className="subtitle">
                            CEO-Fraud-Methoden<br/> & -Schutzmaßnahmen
                            </p> 
                        </div>
                    </div>
                    <div className={state.digitalId}>
                        <div className="box">
                            <div className="image-container">
                                <a className="subtitle" 
                                    href="/ls4"  
                                    target="_blank" rel="noreferrer" 
                                    aria-label="lernszenario 4">
                                    <Img fluid={data.digitalLS4.childImageSharp.fluid} className="image"/>
                                </a>
                            </div>
                            <p className="digitalSmall">KI im Homeoffice</p>
                            <p className="subtitle">
                            Schutzmaßnahmen im <br/> Homeoffice & Smarthome
                            </p> 
                        </div>
                    </div>               
                    <div className={state.digitalId}>
                        <div className="box">
                            <div className="image-container">
                            <a className="subtitle" href="/ls5"  
                                            target="_blank" rel="noreferrer" 
                                            aria-label="lernszenario 5">                                   
                                    <Img fluid={data.digitalLS5.childImageSharp.fluid} className="image"/>
                                </a>
                            </div>
                            <p className="digitalSmall">Alles nur geCLOUD</p>
                            <p className="subtitle">
                                Passwort-Hacking-Methoden <br/> & Passwortschutz
                            </p> 
                        </div>
                    </div>           
                    <div className={state.digitalId}>
                        <div className="box">
                            <div className="image-container">
                            <a className="subtitle" href="/ls6"  
                                            target="_blank" rel="noreferrer" 
                                            aria-label="Security-Game 6">                                   
                                    <Img fluid={data.digitalLS6.childImageSharp.fluid} className="image"/>
                                </a>
                            </div>
                            <p className="digitalSmall">Eine Klassifizierung für sich</p>
                            <p className="subtitle">
                            Info-Klassen <br/> & Verwendungszweck
                            </p> 
                        </div>
                    </div>
                    <div className={state.digitalId}>
                        <div className="box">
                            <div className="image-container">
                            <a className="subtitle" href="/ls7"  
                                            target="_blank" rel="noreferrer" 
                                            aria-label="Security-Game 6">                                   
                                    <Img fluid={data.digitalLS7.childImageSharp.fluid} className="image"/>
                                </a>
                            </div>
                            <p className="digitalSmall">
                                Der Ransomware-Angriff
                            </p>
                            <p className="subtitle">
                                Verschlüsselung & Messenger-Dienste
                            </p> 
                        </div>
                    </div>

                    <div className={state.digitalId}>
                        <div className="box">
                            <div className="image-container">
                                <a className="subtitle" 
                                    href="https://sesec.wildau.biz"  
                                    target="_blank" rel="noreferrer" 
                                    aria-label="lernszenario 1">
                                <Img fluid={data.digitalSeSeC.childImageSharp.fluid} className="image"/>
                                </a>
                            </div>
                            <p className="digitalSmall">Selbsttest</p>
                            <p className="subtitle">App (online)</p>
                        </div>
                    </div>
                    <div className={state.digitalId}>
                        <div className="box">
                            <div className="image-container">
                                <a className="subtitle" href="https://szenarien.wildau.biz/password_hacking_schattenberg2/index.html" 
                                    target="_blank" rel="noreferrer"
                                                aria-label="Online-Game 09">
                                        <Img fluid={data.digitalLSX.childImageSharp.fluid} className="image"/>
                                </a>
                            </div>
                            <p>Passworthacking</p>
                            <p className="subtitle">Karl Schattenberg</p>
                        </div>
                    </div>
                    <div className={state.digitalId}>
                        <div className="whitebox">
                            <div>
                                <div>
                                    &nbsp;
                                </div>
                                <p style={{width: "180px"}}>&nbsp;</p>
                                <p className="subtitle"></p> 
                            </div>
                        </div>  
                    </div>
           
                </div>  

                <div className="ui-arrows">
                    <div className="image left">
                        <Img fluid={data.arrow.childImageSharp.fluid} className="left"/>
                    </div>
                    <div className="image right">
                        <Img fluid={data.arrow.childImageSharp.fluid} className="right"/>
                </div>
            </div>
        </div> 
    </div>      
    )
}

export default DigitalLearningScenarios;
