// Das erste Plakat des ALARM Projekts
import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"


const Flyer1 = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            flyer1: file(relativePath: { eq: "Publications/Flyer/Flyer-1-eng.pdf" }) {
                name
                publicURL
            }
            flyer1Image: file(relativePath: { eq: "Publications/Flyer/Flyer1-image.JPG" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <div className={state.flyerId}>
            <div className="box">
                <div className="image-container">
                    <a href={data.flyer1.publicURL} download>
                        <Img fluid={data.flyer1Image.childImageSharp.fluid} className="image"/> 
                    </a>
                </div>
                <p>Current flyer</p>
                <p className="subtitle">Awareness Lab SME Information Security</p>
            </div>
    </div>
    )
};

export default Flyer1;