import React from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"

const GoalsAndMethodsBlurb = () => {
    const data = useStaticQuery(graphql`
        query {
            roadmap: file(relativePath: { eq: "MethodsAndGoals/Roadmap-eng.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 615, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            methods: file(relativePath: { eq: "MethodsAndGoals/Methods-eng.png" }) {
                childImageSharp {
                    fluid(maxWidth: 375, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <div className="goals-and-methods-blurb">
            <span className="anchor" id="g-and-m">anchor</span>
            <div className="goals">
                <div className="container">
                    <div className="inner-blurb">
                        <div className="content info">
                            <div className="image">
                                <Img fluid={data.roadmap.childImageSharp.fluid}/>
                            </div>
                        </div>
                        <div className="content description">
                            <h3>Goals and Content</h3>
                            <p>
                                Working in cooperation with pilot SMEs and handicraft businesses, the project will systematically infer deficiencies and shortcomings within key business processes using defined activity protocols. 
                                Security and competence profiles will also be derived from the data. Measures will be developed in both analogue and digital form with a view to activating ongoing and far-reaching awareness of security concerns. 
                                These measures will be thoroughly tested and evaluated in a real-world setting. 
                                Best-practice guidelines accompanied by success stories from the participating companies will be promulgated at national level via associated transfer partners in a bid to appeal to other business concerns. 
                                Innovative measures to enhance in-house awareness in SMEs and VSBs are the prelude to a maturity assessment. 
                                The impact analyses are complemented by quality and outcome assurance combined with risk management and an accompanying evaluation. The networking of all participants will be promoted nationwide.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="methods">
                <div className="container">
                    <div className="inner-blurb">
                        <div className="content description">
                            <h3>Methods</h3>
                            <p> 
                                To implement the project’s goals, specific training concepts, awareness-raising concepts, and learning materials, adjusted to the needs of SMEs, will be developed, tested, and evaluated. 
                                Game-Based and Accelerated Learning underpin the experience-oriented learning scenarios, which focus on the area of information security. 
                                Previous studies and research work carried out by Prof. Scholl and the research partners known_sense have indicated that learning processes in the area of information security it is require learners’ emotions to be engaged, 
                                generating a sense of personal motivation. All the materials that are developed will be free for all companies to use at the end of the project as downloads or via online platforms. 
                                This will promote a heightened awareness of IT security and raise security levels all across Germany.
                            </p>
                        </div>
                        <div className="content info">
                            <div className="image methods">
                                <Img fluid={data.methods.childImageSharp.fluid}/>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GoalsAndMethodsBlurb